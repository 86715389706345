<template>
  <a-row class="content-fix" type="flex">
    <a-col class="height100 flex-1">
      <a-tabs class="main-tabs" type="card">
        <a-tab-pane key="content" class="main-tabs" type="card">
          <span slot="tab"> <a-icon type="project" />Содержимое </span>
          <!-- lang tabs -->
          <a-row align="middle" class="form_title sm-mb">
            <a-col :span="4" class="form_title"> Язык: </a-col>
            <a-col :span="12">
              <!-- lang translit tabs -->
              <a-row align="middle" class="flex-baseline flex-wrap">
                <lang-tabs v-model="langTab" class="mb-1x" />
              </a-row>
            </a-col>
          </a-row>

          <a-row align="middle" class="flex-baseline">
            <a-col :span="4" class="form_title"> Название: </a-col>
            <a-col :span="18" class="sm-mb">
              <a-input
                v-model="form['information_' + getKey]"
                class="form_title-input"
                placeholder="Введите название"
              />
            </a-col>
          </a-row>

          <a-row class="sm-mb">
            <a-col :span="4" class="form_title"> Дата публикации: </a-col>
            <a-col :span="12">
              <input v-model="form.pub_date" type="datetime-local" />
            </a-col>
          </a-row>

          <a-row class="sm-mb">
            <a-col :span="4" class="form_title">Файлы документы:</a-col>
            <a-col :span="12">
              <a-button
                v-if="document_files_array.length === 0"
                type="primary"
                @click="$refs.documentRef.click()"
              >
                <input
                  ref="documentRef"
                  accept=".xlsx, .xls, .doc, .docx, .ppt, .pptx, .txt, .pdf"
                  hidden
                  type="file"
                  @change="handleUploadFile"
                />
                <a-icon type="upload" />Выберите файл
              </a-button>

              <span
                v-if="$v.form.file.$dirty && !$v.form.file.required"
                class="error-message"
                >Файл не выбран</span
              >

              <template v-if="document_files_array.length !== 0">
                <p style="padding-left: 15px">Файлы для загрузки</p>
                <InputFileItem
                  v-for="item in document_files_array"
                  :key="item.uid"
                  :file="item.binary_file"
                  :inputcopy="false"
                  class="mt-2 no-a-hover"
                  @delete="deleteDocumentFileFromState(item)"
                />
                <a-row
                  style="border-bottom: 1px solid #ddd; padding-bottom: 15px"
                />
              </template>
            </a-col>
          </a-row>

          <a-row>
            <a-col :span="4" class="form_title" style="padding-top: 2px"
              >Изображение:
            </a-col>
            <a-col :span="12" class="sm-mb">
              <a-button
                style="font-size: 13px"
                type="primary"
                @click="$refs.fileInput.click()"
              >
                <a-icon style="font-size: 16px" type="upload" /> Upload Image
              </a-button>
              <!--<span v-if="!image_url" style="padding-left: 8px">
                Картинка не выбрана
              </span>-->
              <input
                ref="fileInput"
                class="UploadhideInput"
                type="file"
                accept="image/jpg, image/jpeg, image/png, image/svg"
                @change="handleUploadImage"
              />
              <span
                v-if="$v.form.photo.$dirty && !$v.form.photo.required"
                class="error-message"
                >Картинка не выбрана</span
              >
              <div class="committee-image">
                <img :src="image_url" alt="" />
              </div>
            </a-col>
          </a-row>

          <a-row align="middle" class="flex-baseline">
            <a-col :span="4" class="form_title"> Позиция: </a-col>
            <a-col :span="4" class="sm-mb">
              <a-input
                v-model="form.position"
                type="number"
                name="position"
                placeholder="Позиция"
              />
            </a-col>
          </a-row>

          <a-row style="margin-top: 15px">
            <a-col :span="4" class="form_title"> Статус: </a-col>
            <a-switch v-model="form.is_active" />
          </a-row>

          <a-row style="margin-top: 20px">
            <a-col :span="4" />
            <a-col :span="12">
              <a-row>
                <a-button style="margin-right: 10px" @click="$router.go(-1)">
                  <a-icon type="close" />Отмена
                </a-button>
                <a-button type="primary" @click="submitDocument">
                  <a-icon type="check" />Добавить
                </a-button>
              </a-row>
            </a-col>
          </a-row>
        </a-tab-pane>

        <a-tab-pane key="document" class="main-tabs" type="card">
          <span slot="tab"> <a-icon type="file" />Документы </span>

          <a-row align="middle" class="form_title sm-mb">
            <a-col :span="4">
              <a-button type="primary" @click="addAttachment">
                <a-icon type="plus" />Добавить документ
              </a-button>
            </a-col>
          </a-row>

          <DocumentAttachment
            v-for="(item, index) in document_attachments"
            :key="index"
            :item="item"
            class="mt-2 no-a-hover"
            @delete="deleteDocumentAttachment(item)"
          />
        </a-tab-pane>
      </a-tabs>
    </a-col>
  </a-row>
</template>

<script>
import { required } from "vuelidate/lib/validators"
import InputFileItem from "@/components/utils/input-file-item"
import DocumentAttachment from "@/components/utils/document-attachment.vue"
import cloneDeep from "lodash/cloneDeep"

export default {
  components: {
    // "image-upload": () => import("@/components/utils/image-upload")
    InputFileItem,
    DocumentAttachment
  },
  data() {
    return {
      form: {
        information_uz: "",
        information_ru: "",
        information_en: "",
        information_oz: "",
        information_qr: "",
        category: null,
        position: 1,
        photo: null,
        file: null,
        pub_date: null,
        is_active: false
      },
      langTab: "oz",
      image: null,
      image_url: "",
      document_files_array: [],
      document_attachments: []
    }
  },
  computed: {
    getKey() {
      return this.langTab
    }
  },
  created() {
    this.form.category = this.$route.params.cat_id
  },
  methods: {
    async submitDocument() {
      this.$v.$touch()
      if (this.$v.$invalid) {
        return
      }
      let newData = cloneDeep(this.form)
      let f = new FormData()

      Object.keys(newData).forEach((key) => {
        if (newData[key] === null) {
          newData[key] = ""
        }
        f.append(key, newData[key])
      })

      // Set attachments
      this.document_attachments.forEach((item) => {
        let data = JSON.stringify({
          title_uz: item.title_uz,
          title_ru: item.title_ru,
          title_en: item.title_en,
          title_oz: item.title_oz,
          title_qr: item.title_qr
        })
        f.append("attachments", data)
        f.append("attachment_files", item.binary_file)
      })

      /*for (let pair of f.entries()) {
        console.log(pair[0] + "=" + pair[1])
      }*/

      try {
        let res = await this.$api.post("/admin/common/document/create/", f)
        if (res && res.status === 201) {
          this.$message.success("Успешно создано")
          await this.$router.push({ name: "reviews-docs" })
        } else {
          this.$message.error("Ошибка при добавлении")
        }
      } catch (e) {
        this.$message.error("Ошибка при добавлении")
        console.log(e)
      }
    },
    deleteDocumentFileFromState(e) {
      this.form.file = null
      this.document_files_array = this.document_files_array.filter((file) => {
        return file.uid !== e.uid
      })
      // console.log(this.document_files_array)
    },
    handleUploadImage(e) {
      // console.log("from create vue", e.target.files)
      if (e.target.files && e.target.files.length !== 0) {
        this.image_url = URL.createObjectURL(e.target.files[0])
        this.form.photo = e.target.files[0]
        e.target.value = null
      }
    },
    handleUploadFile(e) {
      // console.log(e.target.files)
      if (e.target.files && e.target.files.length !== 0) {
        Array.from(e.target.files).forEach((item) => {
          this.document_files_array = [
            ...this.document_files_array,
            {
              binary_file: item,
              uid: Math.random().toString().slice(2)
            }
          ]
        })
        this.form.file = e.target.files[0]
        e.target.value = null
      }
      // console.log("array", this.document_files_array)
    },
    addAttachment() {
      this.document_attachments.push({
        uid: Math.random().toString().slice(2),
        binary_file: null,
        title_uz: "",
        title_ru: "",
        title_en: "",
        title_oz: "",
        title_qr: ""
      })
    },
    deleteDocumentAttachment(item) {
      this.document_attachments = this.document_attachments.filter((el) => {
        return el.uid !== item.uid
      })
    }
  },
  validations: {
    form: {
      file: {
        required
      },
      photo: {
        required
      }
    }
  }
}
</script>

<style scoped>
.committee-image {
  overflow: hidden;
  margin-top: 15px;
}
.committee-image > img {
  height: 200px;
}
</style>
